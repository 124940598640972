import { JWT } from "aws-amplify/auth";

import { PERMISSION_GROUPS } from "./auth.constants";

export function getGroupsFromCognito(user: JWT): string[] | null {
  return user?.payload["cognito:groups"] as string[] ?? null;
}

export function hasAccessToApp(user: JWT, group: PERMISSION_GROUPS): boolean {
  return !!getGroupsFromCognito(user)?.includes(group);
}

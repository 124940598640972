import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify/utils";
import { signInWithRedirect, signOut as cognitoSignOut, fetchAuthSession, JWT } from "aws-amplify/auth";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { DConfOutput } from "dconf-service/src/types";

import { getGroupsFromCognito, hasAccessToApp } from "./utils";
import { PERMISSION_GROUPS, PROVIDER_ACCESS_DENIED } from "./auth.constants";

interface AuthState {
  userId: string | null;
  userName: string | null;
  givenName: string | null;
  cognitoGroups: string[] | null;
  hasAssembleAccess: boolean;
  hasConnectAccess: boolean;
  hasOptimiseAccess: boolean;
  isAuthenticated: boolean | null;
  isLoading: boolean;
  errorMessage: string | null | undefined;
  domainConfig: DConfOutput | null;
  issuer: string | null;
  init: (config: DConfOutput) => void;
  getDomainConfig: () => Promise<DConfOutput>;
  checkSession: () => Promise<boolean>;
  federatedSignIn: (customProvider: string) => Promise<unknown>;
  signOut: () => Promise<void>;
}

export const useAuthStore = create<AuthState>()(
  devtools(
    immer((set) => {
      const updateUser = (user: JWT | null) => {
        set((state) => {
          if (!user) {
            state.isAuthenticated = false;
            state.userId = null;
            state.userName = null;
            state.givenName = null;
            state.cognitoGroups = null;
            state.hasAssembleAccess = false;
            state.hasConnectAccess = false;
            state.hasOptimiseAccess = false;

            return;
          }

          state.isAuthenticated = true;
          state.userId = user.payload.sub!;
          state.userName = user.payload.family_name as string;
          state.givenName = user.payload.given_name as string;

          state.cognitoGroups = getGroupsFromCognito(user);
          state.hasAssembleAccess = hasAccessToApp(user, PERMISSION_GROUPS.ASSEMBLE);
          state.hasConnectAccess = hasAccessToApp(user, PERMISSION_GROUPS.CONNECT);
          state.hasOptimiseAccess = hasAccessToApp(user, PERMISSION_GROUPS.OPTIMISE);
        });

        return;
      };

      const setLoading = (loading: boolean) =>
        set((state) => {
          state.isLoading = loading;
        });

      const setErrorMessage = (message: string | null | undefined) =>
        set((state) => {
          state.errorMessage = message;
        });

      const setSuccess = () => {
        setLoading(false);
        setErrorMessage(null);
      };

      const setFailure = (message: string | null | undefined) => {
        setLoading(false);
        setErrorMessage(message);
      };

      const setDomainConfig = (config: DConfOutput) =>
        set((state) => {
          if (!state.domainConfig) {
            state.domainConfig = config;
            state.issuer = config.auth.federatedSignIn?.[0].issuer || null;
          }
        });

      return {
        userId: null,
        userName: null,
        givenName: null,
        cognitoGroups: null,
        hasAssembleAccess: false,
        hasConnectAccess: false,
        hasOptimiseAccess: false,
        isAuthenticated: null,
        isLoading: false,
        errorMessage: null,
        domainConfig: null,
        issuer: null,

        init: (config: DConfOutput) => {
          Amplify.configure({
            Auth: {
              Cognito: {
                userPoolId: config.auth.cognito.userPoolId,
                userPoolClientId: config.auth.cognito.userPoolWebClientId,
                loginWith: {
                  oauth: {
                    ...config.auth.cognito.oauth,
                    redirectSignIn: [config.auth.cognito.oauth.redirectSignIn],
                    redirectSignOut: [config.auth.cognito.oauth.redirectSignOut],
                    scopes: config.auth.cognito.oauth.scope,
                    responseType: config.auth.cognito.oauth.responseType as "code" | "token",
                  },
                },
              },
            },
          });
          Hub.listen("auth", async ({ payload: { event, message } }) => {
            switch (event) {
              case "signedIn":
              case "signInWithRedirect":
                updateUser((await fetchAuthSession()).tokens?.idToken ?? null);
                setSuccess();

                break;
              case "signedOut":
                updateUser(null);
                setSuccess();
                break;
              case "signInWithRedirect_failure":
                if (message === PROVIDER_ACCESS_DENIED) {
                  console.error("You need the tile to be assigned!");
                }

                setFailure(message);
                break;
              case "tokenRefresh":
                updateUser((await fetchAuthSession()).tokens?.idToken ?? null);
                setSuccess();
                break;
              case "tokenRefresh_failure":
                setFailure(message);
                updateUser(null);
                break;
            }
          });
        },

        getDomainConfig: async (): Promise<DConfOutput> => {
          const response = await fetch(`/oauth-config?domain=${window.location.hostname}&source=lp`);

          if (response.ok) {
            const config = await response.json();

            setDomainConfig(config.data);

            return config.data;
          } else {
            const text = await response.text();

            return Promise.reject(new Error(text));
          }
        },

        checkSession: () => {
          setLoading(true);

          return fetchAuthSession()
            .then(async ({ tokens }) => {
              updateUser(tokens?.idToken ?? null);
              setLoading(false);

              return true;
            })
            .catch(() => {
              updateUser(null);
              setLoading(false);

              return false;
            });
        },

        federatedSignIn: (customProvider = "ICDev") => {
          setLoading(true);

          return signInWithRedirect({ provider: {
            custom: customProvider,
          } }).catch((error) => {
            console.error("federate login error", error);
            setLoading(false);

            return Promise.reject(error);
          });
        },

        signOut: async () => {
          return cognitoSignOut();
        },
      };
    })
  )
);

const { init, checkSession, federatedSignIn, getDomainConfig, signOut } = useAuthStore.getState();

export const authActions = {
  init,
  checkSession,
  federatedSignIn,
  getDomainConfig,
  signOut,
};
